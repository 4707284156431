exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platform-invitation-js": () => import("./../../../src/pages/platform/invitation.js" /* webpackChunkName: "component---src-pages-platform-invitation-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-why-join-js": () => import("./../../../src/pages/why-join.js" /* webpackChunkName: "component---src-pages-why-join-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/Legal.js" /* webpackChunkName: "component---src-templates-legal-js" */)
}

