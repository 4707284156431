import React, { createContext, useContext, useState, useEffect } from 'react';

const HelpContentContext = createContext();

export const HelpContentProvider = ({ children }) => {
  const [helpContent, setHelpContent] = useState(null);

  useEffect(() => {
    const fetchAllContentFromAPI = async () => {
      try {
        const response = await fetch('https://api.ctrl-s.io/content/suppliers/help/v1/?locale=all');
        const data = await response.json();
        const allContent = data.data.filter(item => item.attributes.slug === 'invitation-landing');
        setHelpContent(allContent); // Save all relevant content directly to state
      } catch (error) {
        console.error("Error fetching all help content:", error);
      }
    };
    fetchAllContentFromAPI();
  }, []);

  return (
    <HelpContentContext.Provider value={helpContent}>
      {children}
    </HelpContentContext.Provider>
  );
};

export const useHelpContent = () => useContext(HelpContentContext);
