import './src/styles/webfonts.css'
import './src/styles/global.css'
import './src/styles/manual.css'
import "./src/assets/css/plyr.css"

import React from 'react';
import { HelpContentProvider } from './src/components/platform/HelpContentContext'; // Adjust the path as necessary

export const wrapRootElement = ({ element }) => {
  return <HelpContentProvider>{element}</HelpContentProvider>;
};
